$grid-breakpoints: (
  xs: 0,
  sm: 999px,
  md: 1000px,
  lg: 1200px,
  xl: 1400px,
  xxl: 2200px,
);

// BS overwrites
$primary: #ff4545;
$secondary: #ffbf00;
$dark: #343a40;
$light: white;

// COLOURS
$body-bg: #4f4f4f;
$body-color: #efefef;
$link-color: $secondary;
$input-bg: white;
$input-color: $dark;
$input-focus-border-color: $secondary;
$input-focus-box-shadow: 0px 0px 0px 2px $secondary;
$input-box-shadow: $secondary;
$input-focus-bg: lighten($secondary, 30%);
$section-grey: #555555;
$form-check-input-checked-bg-color: $secondary;
$form-check-input-checked-border-color: $secondary;
$table-color: white;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'dark': $dark,
);

// FONTS
$font-family-base: 'Montserrat', sans-serif;

@import '~bootstrap/scss/bootstrap.scss';

// CUSTOM CLASSES & STYLES
html,
body {
  min-height: 100vh !important; // needs to be here, otherwise Chrome sets smaller value
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  color: white;
}
div {
  text-align: justify;
  text-justify: inter-word;
}
label,
td {
  text-align: left;
}

.section-area {
  min-height: calc(100vh - 114px);
}

.btn-primary {
  @include button-variant(
    $primary,
    darken($primary, 7.5%),
    white,
    darken($primary, 5%),
    darken($primary, 5%),
    white
  );
}
.btn-secondary {
  @include button-variant(
    $secondary,
    darken($secondary, 7.5%),
    white,
    darken($secondary, 5%),
    darken($secondary, 5%),
    white
  );
}
.btn.btn-secondary:active {
  color: white;
}
.btn.btn-primary[disabled] {
  color: $body-color;
}
.btn-,
.btn-close {
  width: 25px;
  height: 25px;
  background-color: transparent;
  background-image: url('./assets/icons/cancel.svg');
  border: 0;
  margin-top: -5px;
}

.nav-link {
  color: $secondary;
  font-weight: bold;
  font-size: 18px;
}
.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  color: white;
}
.dropdown-menu {
  min-width: 5.4rem;
  border: 0;
}
.logo {
  height: 58px;
  .logo-icon {
    display: inline-block;
    margin-left: 12px;
    padding-left: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40px;
    width: 40px;
  }
  .logo-grey {
    background-image: url('./assets/images/logo-grey.png');
  }
  .logo-yellow {
    background-image: url('./assets/images/logo-yellow.png');
  }
  .logo-red {
    background-image: url('./assets/images/logo-red.png');
  }
}
.logo:hover {
  .logo-grey {
    background-image: url('./assets/images/logo-grey-full.png');
  }
  .logo-yellow {
    background-image: url('./assets/images/logo-yellow-full.png');
  }
  .logo-red {
    background-image: url('./assets/images/logo-red-full.png');
  }
}
.liked,
.error {
  color: #dd4242;
}
input.error {
  box-shadow: 0px 0px 0px 2px $primary;
}

.form-part {
  background-color: $section-grey;
  padding: 20px 40px;
}

.date-picker {
  display: block !important;
  padding-bottom: 8px;
}

.footer {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.results-chart {
  background-color: $section-grey;
}

.dti-card {
  background-color: #4f4f4f;
  border: none;
}

.forum {
  max-width: 600px;
  margin: auto;
}
.tweet-right {
  text-align: right !important;
  i {
    cursor: pointer;
  }
  i:hover {
    color: $secondary;
  }
}
.tweet-left {
  text-align: left !important;
}
.tweet-content {
  text-align: justify !important;
  background-color: $section-grey;
}

.pagination {
  justify-content: center;
  li {
    margin: 2px;
    a {
      color: #fff !important;
      outline: none;
      padding: 10px 15px;
      background-color: $secondary;
      border-radius: 3px;
    }
    a:hover {
      background-color: darken($color: $secondary, $amount: 5);
    }
  }
  li.disabled {
    a {
      pointer-events: none;
      background-color: lighten($color: $section-grey, $amount: 20);
    }
  }
  li.active {
    a {
      pointer-events: none;
      background-color: $primary;
    }
  }
}

@media print {
  body,
  a,
  .results,
  .results-chart,
  .form-part,
  .bg-dark,
  .dti-card,
  .table {
    -webkit-print-color-adjust: exact; /* chrome */
    print-color-adjust: exact;
    background-color: white !important;
    color: black !important;
  }
  button,
  header,
  .status-row,
  .ad-turn-off,
  .google-ad {
    display: none !important;
  }
  text {
    fill: black;
  }
}
@page {
  margin: 60px;
}
